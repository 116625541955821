import React from 'react'

import Container from '../container'
import style from './index.module.css'

const Features = () => (
  <Container>
    <dl className={style.list}>
      <div className={`${style.square} ${style.rate}`}>
        <dt className={style.subheader}>
          Simple, hands-off multivariate testing
        </dt>
        <dd className={style.feature}>
          No confusing data, no guesswork. Tweeker algorithmically runs
          your multivariate and A/B tests, then displays the version
          that converts best, so you sell more.
          It’s the easiest way to increase your conversion rates.
        </dd>
      </div>
      <div className={`${style.square} ${style.noCoding}`}>
        <dt className={style.subheader}>No coding knowledge needed</dt>
        <dd className={style.feature}>
          Tweeker works through a small code snippet you copy and paste
          into your website. Or you can install one of our plugins, like
          Wordpress or Shopify.
          You'll be testing all your variations in minutes.
        </dd>
      </div>
      <div className={`${style.square} ${style.installation}`}>
        <dt className={style.subheader}>Insanely easy to use</dt>
        <dd className={style.feature}>
          Tweeker’s simple interface makes it feel like you’re
          directly editing your site. Create new variations and set
          new goals at any time. Tweeker will handle the A/B testing
          and conversion rate optimization for you.
        </dd>
      </div>
      <div className={`${style.square} ${style.personalData}`}>
        <dt className={style.subheader}>No personal data stored</dt>
        <dd className={style.feature}>
          Tweeker protects your privacy and that of your customers.
          Our software is GDPR compliant and doesn't store any
          personally identifiable information of people who visit your website.
        </dd>
      </div>
    </dl>
  </Container>
)

export default Features