import React from 'react'
import PropTypes from 'prop-types'

import style from './index.module.css'

const Pricing = ({ name, cost, views, url, children }) => {
  const classes = `${style['pricing' + name]} ${style.pricingContainer}`
  return(
    <div className={classes}>
      <h3 className={style.name}>{name}</h3>
      <h3 className={style.cost}>
        ${cost}
        <span className={style.per}>/ month</span>
      </h3>
      <h4 className={style.pageViews}>Up to {views} page views</h4>
      <ul className={style.features}>{children}</ul>
      <a className={style.button} href={url + name.toLowerCase()}>Start 2 Week Free {name} Trial</a>
    </div>
  )
}

Pricing.propTypes = {
  name: PropTypes.string,
  cost: PropTypes.number,
  views: PropTypes.string,
  url: PropTypes.string,
  children: PropTypes.node
}

Pricing.defaultProps = {
  name: `Individual`,
  cost: 15,
  views: '10k',
  url: 'https://app.tweeker.io/sign-up?plan='
}

export default Pricing