import React from 'react'
import Typist from 'react-typist'

import Container from '../container'
import style from './index.module.css'

const cursorOptions = {
  hideWhenDone: true
}

const stringLength = (string) => {
  return string.length
}

const Splash = () => (
  <Container>
    <div className={style.splash}>
      <h1 className={style.subheader}>Multivariate testing made easy.</h1>
      <h2 className={style.header}>
        Tweeker runs your multivariate tests,
        then automatically displays what’s {' '}
        <Typist cursor={cursorOptions} className={style.typist}>
          getting clicks.
          <Typist.Backspace count={stringLength('getting clicks.')} delay={3000} />
          growing subscribers.
          <Typist.Backspace count={stringLength('growing subscribers.')} delay={3000} />
          landing customers.
        </Typist>
      </h2>
    </div>
  </Container>
)

export default Splash