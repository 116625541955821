import React from 'react'

import Layout from '../components/layout'
import Features from '../components/features'
import Image from '../components/image'
import SEO from '../components/seo'
import Pricing from '../components/pricing'
import Container from '../components/container'
import Header from '../components/header'
import Splash from '../components/splash'


import style from './index.module.css'

const IndexPage = () => (
  <Layout>
    <SEO
      title='Multivariate testing made easy - Tweeker'
      canonical='https://tweeker.io'
    />
    <div className={style.cta}>
      <Header  />
      <Splash />
    </div>
    <Features />
    <div className={style.resultsSection}>
      <Container>
        <h4 className={style.resultsHeader}>Easily follow your site's improving conversion rate</h4>
        <h5 className={style.resultsSubheader}>
          As Tweeker finds what’s converting the best,
          you can always see and compare the results of your
          multivariate and A/B tests.
        </h5>
        <div style={{ maxWidth: `100%`, marginBottom: `1.45rem` }}>
          <Image />
        </div>
      </Container>
    </div>

    <div className={style.pricing}>
      <Container>
        <h4 className={style.pricingHeader}>Simple, affordable pricing. No surprises.</h4>
        <div className={style.pricingExplainer}>
          Plan levels are based on site traffic.
          No hidden fees, no complicated calculations.
        </div>

        <div className={style.pricingGrid}>
          <Pricing name='Individual' cost={15} views='10k'>
            <li>Unlimited Pages</li>
            <li>1 Domain</li>
            <li>1 User Account</li>
          </Pricing>

          <Pricing name='Business' cost={45} views='100k'>
            <li>Unlimited Pages</li>
            <li>1 Domain</li>
            <li>Unlimited User Accounts</li>
          </Pricing>

          <Pricing name='Enterprise' cost={150} views='1M'>
            <li>Unlimited Pages</li>
            <li>3 Domains</li>
            <li>Unlimited User Accounts</li>
          </Pricing>
        </div>

        <p className={style.more}>
          Need more than a million page views? Want to test unlimited domains?
          {' '}
          <a
            className={style.contact}
            href='mailto:hello@tweeker.io'
          >Contact Us</a>
        </p>
      </Container>
    </div>
  </Layout>
)

export default IndexPage